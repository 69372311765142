<template>
	<article>
		<QuestionBankQuestionContent
			v-for="question in myQuestions"
			:key="question.id"
			:question="question"
		/>
	</article>
</template>

<script>
import QuestionBankQuestionContent from "./components/QuestionBankQuestionContent.vue";
export default {
	name: "QuestionBankMyQuestion",
	components: { QuestionBankQuestionContent },
	props: ["myQuestions"],
	data() {
		return {};
	}
};
</script>

<style></style>
